<template>
  <b-card-text>

    <template v-for="(prop, name) in $props">
      <template v-if="name === 'delivery_status'">
        <template v-if="prop === 2">
          <feather-icon
            :key="name"
            class="mb-2"
            icon="TruckIcon"
            size="18"
            opacity="0"
          />
        </template>
        <template v-if="prop === 1">
          <feather-icon
            :key="name"
            class="mb-2"
            icon="TruckIcon"
            size="18"
          />
        </template>
        <template v-if="prop === 0">
          <feather-icon
            :key="name"
            class="mb-2"
            icon="HomeIcon"
            size="18"
          />
        </template>
      </template>

      <template v-else-if="name === 'number_daily'">
        <p
          :key="name"
          class="text-sm-truncate text-right text-danger font-weight-bolder font-medium-4 mt-n3">{{ prop ? prop : '-' }}</p>
      </template>

      <template v-else-if="name === 'courier_full_name'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span>{{ titles[name] }}</span>
          <span class="text-sm-truncate text-right text-info">{{ prop ? prop : '-' }}</span>
        </p>
      </template>

      <template v-else-if="name === 'delivery_interval_from'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate">{{ titles[name] }} </span>
          <span class="text-truncate">{{ $dayjs(prop * 1000).format('DD MMM HH:mm') }}</span>
        </p>
      </template>

      <template v-else-if="name === 'delivery_interval_to'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate">{{ titles[name] }} </span>
          <span class="text-truncate">{{ $dayjs(prop * 1000).format('DD MMM HH:mm') }}</span>
        </p>
      </template>

      <template v-else-if="name === 'pay_status'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate"> {{ titles[name] }}: </span>
          <span class="text-truncate"> {{ prop ? payStatus[prop]: '-' }} </span>
        </p>
      </template>

      <template v-else-if="name === 'client_phone'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate"> {{ titles[name] }}: </span>
          <template v-if="prop">
            <a
              @click.stop="onTel(prop)"
              class="text-primary"
            > {{ prop | VMask('+#(###) ###-##-##') }} </a>
          </template>
          <template v-else> - </template>
        </p>
      </template>

      <template v-else-if="name === 'courier_phone'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate"> {{ titles[name] }}: </span>
          <template v-if="prop">
            <a
              @click.stop="onTel(prop)"
              class="text-primary"
            > {{ prop | VMask('+#(###) ###-##-##') }} </a>
          </template>
          <template v-else> - </template>
        </p>
      </template>

      <template v-else-if="name === 'recipient_phone'">
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate"> {{ titles[name] }}: </span>
          <template v-if="prop">
            <a
              @click.stop="onTel(prop)"
              class="text-primary"
            > {{ prop | VMask('+#(###) ###-##-##') }} </a>
          </template>
          <template v-else> - </template>
        </p>
      </template>

      <template v-else>
        <p
          :key="name"
          class="d-flex justify-content-between"
        >
          <span class="text-truncate"> {{ titles[name] }}: </span>
          <span class="text-truncate"> {{ prop ? prop: '-' }} </span>
        </p>
      </template>
    </template>
  </b-card-text>
</template>

<script>
import {OrderPayStatus} from '../../store/modules/order/types'

export default {
  name: 'OrderInfo',
  props: {
    delivery_status: {
      type: Number,
      default() {
        return null
      },
    },
    number_daily: {
      type: Number,
      default() {
        return null
      },
    },
    number: {
      type: Number,
      require: true,
    },
    order: {
      type: Number,
      default() {
        return 0
      },
    },
    courier_full_name: {
      type: String,
      default() {
        return ''
      },
    },
    delivery_service: {
      type: String,
      default() {
        return ''
      },
    },
    courier_phone: {
      type: String,
      default() {
        return ''
      },
    },
    delivery_interval_from: {
      type: Number,
      default() {
        return 0
      },
    },
    delivery_interval_to: {
      type: Number,
      default() {
        return 0
      },
    },
    client_full_name: {
      type: String,
      default() {
        return ''
      },
    },
    client_phone: {
      type: String,
      default() {
        return ''
      },
    },
    recipient_full_name: {
      type: String,
      default() {
        return ''
      },
    },
    recipient_phone: {
      type: String,
      default() {
        return ''
      },
    },
    delivery_address: {
      type: String,
      default() {
        return ''
      },
    },
    pay_status: {
      type: Number,
      default() {
        return 0
      },
    },
    delivery_price: {
      type: String,
    },
    total_price: {
      type: String,
    },
    order_source: {
      type: String,
    },
  },
  computed: {
    titles() {
      return {
        number: 'Номер заказа',
        delivery_interval_from: 'Доставка с',
        delivery_interval_to: 'Доставка по',
        delivery_service: 'Служба доставки',
        courier_phone: 'Телефон курьера',
        courier_full_name: 'Имя курьера',
        client_full_name: 'Имя заказчика',
        client_phone: 'Телефон заказчика',
        recipient_full_name: 'Имя получателя',
        recipient_phone: 'Телефон получателя',
        delivery_address: 'Адрес доставки',
        pay_status: 'Статус оплаты',
        delivery_price: 'Стоимость доставки',
        total_price: 'Всего к оплате',
        order_source: 'Источник заказа',
        order: 'Очередь',
      }
    },
    payStatus() {
      return OrderPayStatus
    },
  },
  methods: {
    onTel(prop) {
      document.location.href = `tel:${+prop}`
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
